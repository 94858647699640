/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-12-10T13:49:15+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2022-08-05T12:22:02+02:00
 * @Copyright: Diversitude 2021
 */

import store from "@/state/store";

export default [
  {
    path: "/testzone",
    name: "testzone",
    meta: {
      authRequired: true
    },
    component: () => import("./views/testzone3")
  },
  {
    path: "/typography",
    name: "typography",
    meta: {
      authRequired: true
    },
    component: () => import("./views/typography")
  },
  {
    path: "/scorecard",
    name: "scorecard",
    meta: {
      authRequired: true
    },
    component: () => import("./views/menu-pages/scorecard")
  },
  {
    path: "/planning",
    name: "planning",
    meta: {
      authRequired: true
    },
    component: () => import("./views/menu-pages/planning.vue")
  },
  // {
  //   path: "/privacy&legal",
  //   name: "Privacy & Legal",
  //   meta: {
  //     authRequired: true
  //   },
  //   component: () => import("./views/utility/privacyAndLegal.vue")
  // },
  /*{
    path: '/testzone/:id',
    name: 'testzone',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/testzone'),
},*/
  {
    path: "/",
    name: "home",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true,
    },
    component: () => import("./views/dashboards/home")
  },
  {
    path: "/people",
    name: "people",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/admin/peopleNew.vue")
  },

  {
    path: "/chat",
    name: "chat",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/menu-pages/chat.vue")
  },
  {
    path: "/people/:user_id",
    name: "people_id",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/admin/peopleNew.vue")
  },
  {
    path: "/new-user",
    name: "new-user",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/admin/addNewUser.vue")
  },
  {
    path: "/people/:user_id",
    name: "person",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/admin/person.vue")
  },

  {
    path: "/performance",
    name: "performance",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/menu-pages/objectives.vue") //*objectives
  },
  {
    path: "/scheduler",
    name: "scheduler",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/menu-pages/scheduler.vue") //*scheduler
  },
  {
    path: "/model",
    name: "model",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/menu-pages/model.vue") //*scheduler
  },
  /*{
    path: '/objectives/:objective_id',
    name: 'objective-details',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/menu-pages/objective-details.vue')
  },*/
  /*{
    path: '/activity',
    name: 'activity',
    meta: {
      authRequired: true,
    },
    component: () => import('./views/menu-pages/activity.vue')
  },*/
  {
    path: "/approvals",
    name: "approvals",
    meta: {
      authRequired: true
    },
    component: () => import("./views/menu-pages/approvals.vue")
  },
  {
    path: "/handbook/:document_param", // document id
    name: "handbook-with-param",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/menu-pages/process.vue")
  },
  {
    path: "/handbook",
    name: "handbook",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/menu-pages/process.vue")
  },
  {
    path: "/scorecard",
    name: "scorecard",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/menu-pages/scorecard.vue")
  },

  {
    path: "/meetings",
    name: "meetings",
    meta: {
      authRequired: true,
      unsavedChangesCheck: true
    },
    component: () => import("./views/menu-pages/meetings.vue")
  },
  {
    path: "/login",
    name: "login",
    component: () => import("./views/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        let has_session_token = store.getters["auth/loggedIn"];
        // If the user is already logged in

        if( has_session_token == false){
          //* Continue to login if token is invalid
          next();
        }
        else{
          //* Let home route redirect user based on role, after it loads core_data from admin store
          next({ name: 'home'});
        }
      }
    }
  },
  {
    path: "/register",
    name: "register",
    component: () => import("./views/account/register"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ ...routeTo });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/forgot-password",
    name: "forgot",
    component: () => import("./views/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["auth/loggedIn"]) {
          // Redirect to the home page instead
          next({ ...routeTo });
        } else {
          // Continue to the login page
          next();
        }
      }
    }
  },
  {
    path: "/reset-password/:token",
    name: "reset",
    component: () => import("./views/account/reset-password"),
    meta: {
      authRequired: false,
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      authRequired: true,
      beforeResolve(routeTo, routeFrom, next) {
        //TODO Remove this jank -- use auth/logOut (sets user token to null)
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else {
          store.dispatch("authfack/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some(route =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(authRequiredOnPreviousRoute ? { name: "home" } : { ...routeFrom });
      }
    }
  },
  {
    path: "/404",
    name: "404",
    component: require("./views/utility/404").default
  },
  // Redirect any unmatched routes to the 404 page. This may
  // require some server configuration to work in production:
  // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
  {
    path: "*",
    redirect: "404"
  }
];
