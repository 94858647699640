

import store from '@/state/store';
//eslint-disable-next-line
import { format } from 'date-fns';

export const state = {
    is_loading_scorecard: false,
    scorecard_templates: [],
    scorecards: [],
    loaded_template: null,
    loaded_user_scorecard: null,
    selected_scorecard_template_id: -1,
    selected_scorecard_user_id: -1,
    weight_modal_data: null,
    details_page_data: {},
    details_page_type: null,
    scorecardFilters: [],
    search_employee_scorecard_names: '',
};

export const getters = {
    viewingUserType(state){
        return state.loaded_user_scorecard.job_data.user_id == store.state.admin.core.id
        ? 'employee' : 'manager';
    },
    selectedEmployeeCard(state){
        //(x) => x.language === i18n.locale);
        return state.scorecards.find( (card) => card.id == state.selected_scorecard_user_id );
    }
    //viewingUserType: (state) => () => state.details_page_data.job_
  };

export const mutations = {
    setSearchEmployeeScorecardNamesText(state, payload){
        state.search_employee_scorecard_names = payload;
    },
    setScorecardTemplates(state, payload){
        state.scorecard_templates = payload;
    },
    setScorecardTemplate(state, payload){
        state.loaded_template = payload;
    },
    setUserScorecard(state, scorecard){
        state.loaded_user_scorecard = scorecard;
    },
    setSelectedScorecardTemplateID(state, id){
        state.selected_scorecard_template_id = id;
    },
    setSelectedScorecardUserID(state, id){
        state.selected_scorecard_user_id = id;
    },
    setLoadingState(state, value){
        state.is_loading_scorecard = value;
    },
    setWeightModalData(state, data_object){
        state.weight_modal_data = data_object;
    },
    setDetailsPageData(state, value) {
        state.details_page_data = value;
    },
    setDetailsPageType(state, value){
        state.details_page_type = value;
    },
    setLevelScorecards(state, value) {
        state.scorecards = value
    },
    setScorecardFilters(state, value) {
        state.scorecardFilters = value
    }
}

export const actions = {
    setSearchEmployeeScorecardNamesText({commit}, text){
        commit('setSearchEmployeeScorecardNamesText', text);
    },
    //eslint-disable-next-line
    loadLevelScorecards({commit}, params){
        return new Promise( (resolve, reject) => {
            window.axios.get('api/scorecards/level_scorecards', { params: params})
            .then((response) => {
                console.log(response)
                commit('setLevelScorecards', response.data);
                resolve();
            })
            .catch((error)=>{
                console.error(error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadScorecardTemplates({commit}, params){
        //? {  show_archived }
        return new Promise( (resolve, reject) => {
            window.axios.get('api/scorecards/templates', { params: params})
            .then((response) => {
                console.log(response)
                commit('setScorecardTemplates', response.data);
                resolve();
            })
            .catch((error)=>{
                console.error(error);
                reject();
            })
        })
    },

    //eslint-disable-next-line
    loadScorecardTemplate({commit}, params){
        commit('setLoadingState', true);
        //eslint-disable-next-line
        return new Promise( (resolve, reject) => {
            window.axios.get('api/scorecards/template', { params: params})
            .then((response) => {
                commit('setScorecardTemplate', response.data);
                commit('setLoadingState', false);
                resolve();
            })
            .catch((error)=>{
                commit('setLoadingState', false);
                reject();
                console.error(error);
            })
        })
    },
    //eslint-disable-next-line
    loadUserScorecard({commit}, scorecard_params){
        commit('setLoadingState', true);
        const range = store.getters['performance/objectives/viewableRangeDate'];
        let params = {
            template_id: scorecard_params.template_id,
            user_id: scorecard_params.user_id,
            period_start_date: format(new Date(range.range_start), 'yyyy-MM-dd'),
            period_end_date: format(new Date(range.range_end), 'yyyy-MM-dd'),
            job_id: scorecard_params.job_id,
        }

        return new Promise( (resolve, reject) => {
            window.axios.get('api/scorecards/user_scorecard', { params: params})
            .then((response) => {
                commit('setSelectedScorecardTemplateID', scorecard_params.template_id);
                commit('setUserScorecard', response.data);
                commit('setLoadingState', false);
                resolve();
            })
            .catch(()=>{
                commit('setLoadingState', false);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadScorecardSummaryComments({commit}, period_id){
        return new Promise( (resolve, reject) => {
            window.axios.get('api/scorecards/scorecard_comments', { params: { scorecard_period_id: period_id}})
            .then((response) => {
                // commit('setUserScorecard', response.data);
                // commit('setLoadingState', false);
                resolve(response);
            })
            .catch((error)=>{
                //commit('setLoadingState', false);
                reject(error);
                //console.error(error);
            })
        })
    },
    //eslint-disable-next-line
    saveJobScorecardTemplates({}, params){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/scorecards/save_job_templates', params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error)=>{
                reject();
                console.error(error);
            })
        })
    },
    //eslint-disable-next-line
    saveDefaultJobScorecardTemplate({}, params){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/scorecards/save_default_job_template', params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error)=>{
                reject();
                console.error(error);
            })
        })
    },

    //eslint-disable-next-line
    saveScorecardTemplate({}, params){
        //? { id, archived, name, description }
        //eslint-disable-next-line
        return new Promise( (resolve, reject) => {
            window.axios.post('api/scorecards/save_template', params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error)=>{
                reject();
                console.error(error);
            })
        })
    },
    //eslint-disable-next-line
    updateScorecardComment({}, params){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/scorecards/edit_comment', params)
            .then(() => {
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    saveClientScorecardTemplateSections({}, params){
        //eslint-disable-next-line
        return new Promise( (resolve, reject) => {
            window.axios.post('api/scorecards/save_template_sections', params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error)=>{
                reject();
                console.error(error);
            })
        })
    },
    //eslint-disable-next-line
    saveScorecardScore({}, params){
        return new Promise( (resolve, reject) => {
                window.axios.post(`api/scorecards/save_values_score`, params)
                .then((response) => {
                    resolve(response.data);
                })
                .catch((error)=>{
                    reject();
                    console.error(error);
                })
        })
    },
    // eslint-disable-next-line
    saveScorecardData({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post(`api/scorecards/save_scorecard_data`, params)
            .then(() => {
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveScorecardComment({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post(`api/scorecards/create_comment`, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error)=>{
                console.error(error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    duplicateScorecardTemplate({}, params){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/scorecards/duplicate_template', params)
            .then((response)=>{
                resolve(response.data.template_id);
            })
            .catch(()=>{
                reject();
            })
        })
    },
    // eslint-disable-next-line
    archiveScorecardTemplate({}, params){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/scorecards/archive_template', params)
            .then(()=>{
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    // eslint-disable-next-line
    deleteScorecardTemplate({}, params){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/scorecards/delete_template', params)
            .then(()=>{
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    // eslint-disable-next-line
    submitScorecardComments({}, params){
        return new Promise((resolve, reject)=>{
            window.axios.post(`api/scorecards/submit_comments`, params)
            .then((response) => {
                resolve(response.data);
            })
            .catch((error)=>{
                console.error(error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    submitScorecard({}, params){
        return new Promise( (resolve, reject) => {
            window.axios.post('api/scorecards/submit_scorecard', params)
            .then(() => {
                resolve();
            })
            .catch(()=>{
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadProductivityDetails({commit}, params){
        return new Promise( (resolve, reject) => {
            window.axios.get('api/scorecards/productivity_view', { params: params})
            .then((response) => {
                commit('setDetailsPageData', response.data);
                resolve();
            })
            .catch((error)=>{
                console.error(error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadValuesDetails({commit}, params){
        return new Promise( (resolve, reject) => {
            window.axios.get('api/scorecards/values_view', { params: params})
            .then((response) => {
                commit('setDetailsPageData', response.data);
                resolve();
            })
            .catch((error)=>{
                console.error(error);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadObjectiveDetails({commit}, params){
        return new Promise( (resolve, reject) => {
            window.axios.get('api/scorecards/objectives_view', { params: params})
            .then((response) => {
                commit('setDetailsPageData', response.data);
                resolve();
            })
            .catch((error)=>{
                console.error(error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    uploadScorecardMedia({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/scorecards/upload_document', {params})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('document error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    deleteScorecardMedia({}, params) {
        return new Promise((resolve, reject) => {
            window.axios.post('api/scorecards/delete_document', {params})
            .then((response)=>{
                resolve(response.data);
            })
            .catch((error) => {
                reject(error);
            })
        })
    },
    setScorecardFilterValues({commit}, object){
        commit('setScorecardFilters', object);
    },


    setSelectedScorecardTemplateID({commit}, id){
        commit('setSelectedScorecardTemplateID', id);
    },
    setSelectedScorecardUserID({commit}, id){
        commit('setSelectedScorecardUserID', id);
    },

    setWeightModalData({commit}, payload){
        commit('setWeightModalData', payload);
    },
    setDetailsPageType({commit}, payload){
        commit('setDetailsPageType', payload);
    }
    //setSelectedScorecardUserID
};