
import Vue from 'vue';
export const state = {
    //Holds each components state, id is the key and the config is the value
    component_states:{},
    view_item_id: null,
    view_state: null
};

export const getters = {
    
};

export const mutations = {
    setComponentState(state, payload){
        let component_id = payload.component_id;
        let component_state = payload.component_state;
        Vue.set(state.component_states, component_id, component_state)
    },
    setComponentViewState(state, payload){
        state.view_item_id = payload.id
        state.view_state = payload.type;
    }
};

export const actions = {
    setComponentState({commit}, payload){
        commit('setComponentState', payload)
    },
    setComponentViewState({commit}, payload){
        commit('setComponentViewState', payload)
    }
};
