/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-10-19T16:41:12+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2021-11-05T16:19:45+02:00
 * @Copyright: Diversitude 2021
 */

import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import VueApexCharts from 'vue-apexcharts';
import Vuelidate from 'vuelidate';
import VueMask from 'v-mask';
import VueRouter from 'vue-router';
import vco from 'v-click-outside';
import router from './router/index';
import Scrollspy from 'vue2-scrollspy';
import VueSweetalert2 from 'vue-sweetalert2';
import CKEditor from '@ckeditor/ckeditor5-vue2';

import '../src/design/app.scss';

import * as webix from './plugins/webix_10.1.2/webix/webix.min.js';
import './plugins/webix_10.1.2/webix/webix.css';
window.webix = webix;

import EchoManagerPlugin from './plugins/EchoManager.js';

import { registerLicense } from '@syncfusion/ej2-base';

import { DocumentEditorContainerPlugin, DocumentEditorPlugin } from '@syncfusion/ej2-vue-documenteditor';
//eslint-disable-next-line
// import { GanttComponent as EjsGantt } from "@syncfusion/ej2-vue-gantt";
// Vue.component('ejs-gantt', EjsGantt);
// console.log('EJSGANTT', EjsGantt, common);
//Vue.use(EjsGantt);


import '../src/design/css/custom.css';
import '../src/design/css/badges.css';
import '../src/design/css/text-size.css';

import '../src/design/css/figma.css'

import store from '@/state/store';

import App from './App.vue';
import _ from 'lodash';

import i18n from './i18n';

import uploader from 'vue-simple-uploader';


//import { VuePlausible } from 'vue-plausible'

import common from './assets/js/common';
import GlobalMixins from './mixins/global.js';
Vue.mixin(GlobalMixins);

if (process.env.NODE_ENV != 'development') {
  console.log = function() {};
}

webix.editors.$popup.time = {
  view: 'popup',
  body: { view: 'calendar', type: 'time', icons: true },
};

webix.editors.time = webix.extend(
  {
    popupType: 'time',
  },
  webix.editors.date
);

webix.editors.$popup.yearly = {
  view: 'popup',
  body: {
    view: 'calendar',
    type: 'year',
  },
};

webix.editors.yearly = webix.extend(
  {
    popupType: 'yearly',
  },
  webix.editors.date
);

webix.editors.textarea = webix.extend(
  {
    render: function() {
      return webix.html.create(
        'div',
        {
          class: 'webix_dt_editor webix_custom_text',
        },
        '<textarea>'
      );
    },
  },
  webix.editors.text
);

webix.editors.range2 = {
  focus: function() {
    this.getInputNode().focus();
    this.getInputNode().select();
  },
  getValue: function() {
    return this.getInputNode().value;
  },
  setValue: function(value) {
    this.getInputNode().value = value;
  },
  getInputNode: function() {
    return this.node.firstChild;
  },
  render: function(a, b, c) {
    console.log(a);
    console.log(b);
    console.log(c);
    return webix.html.create(
      'div',
      {
        class: 'webix_dt_editor',
      },
      `<input style="width: 100px" type="range" min="0" max="100" value="50" class="slider"/><span class="ms-1">10</span>`
    );
  },
};

import axios from 'axios';
window.axios = axios;
window.axios.defaults.headers.common = {
  'X-Requested-With': 'XMLHttpRequest',
};
window.axios.defaults.baseURL = process.env.VUE_APP_API_BASEURL;
window.axios.interceptors.request.use(
  function(config) {
    // Do something before request is sent
    var token = localStorage.getItem('x_token'); //get token from local storage
    if (token) {
      // inject token
      const AUTH_TOKEN = token;
      config.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
    }
    return config;
  },
  function(error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

/* Treant plugin with jquery */
//import OrgChart from '@/plugins/treant-vue/orgChart.js'
//Vue.use(OrgChart);

Vue.use(_);
Vue.use(DocumentEditorContainerPlugin);
Vue.use(DocumentEditorPlugin);
//Vue.component('tinymce', tinymce)
Vue.use(VueRouter);
Vue.use(vco);
Vue.use(Scrollspy);
const VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);
Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(Vuelidate);
Vue.use(VueMask);
Vue.use(VueSweetalert2);
Vue.component('apexchart', VueApexCharts);
Vue.use(CKEditor);
Vue.use(uploader);
//Events
Vue.use(EchoManagerPlugin, {
  options: {
    //cluster: 'eu',
    //wsHost: 'realtime-pusher.ably.io',
    authEndpoint: process.env.VUE_APP_API_BASEURL + 'broadcasting/auth',
  },
});
// Vue.use(VuePlausible, {
//   domain: "portal.stratosfia.com",
//   apiHost: "https://plausible.stratosfia.com",
//   trackLocalhost: false,
//   enableAutoPageviews: true
// });

//Vue.config.performance = true;

Vue.prototype.$eventHub = new Vue();
//*Import locale and then set selected locale
//        import(`./plugins/webix_10.1.2/locales/en-ZA`).then(()=>{
//          webix.i18n.setLocale('en-ZA');
//          //this.finishedLoading = true;
//        })
//        webix.skin.mini.barHeight=26;webix.skin.mini.tabbarHeight=34;webix.skin.mini.rowHeight=18;webix.skin.mini.listItemHeight=28;webix.skin.mini.inputHeight=22;webix.skin.mini.layoutMargin.wide=5;webix.skin.mini.layoutMargin.space=2;webix.skin.mini.layoutPadding.space=2;
//        webix.skin.set('mini');

//webix.skin.material.rowHeight=20;//28 old
//webix.skin.material.barHeight=44;webix.skin.material.tabbarHeight=44;webix.skin.material.rowHeight=26;webix.skin.material.listItemHeight=36;webix.skin.material.inputHeight=38;webix.skin.material.layoutMargin.wide=10;webix.skin.material.layoutMargin.space=10;webix.skin.material.layoutPadding.space=10;
//webix.skin.set('material');
function data_handler(value) {
  //eslint-disable-next-line no-undef
  var view = $$(this.webixId);

  if (typeof value === 'object') {
    if (this.copyData) value = webix.copy(value);

    if (view.setValues) view.setValues(value);
    else if (view.parse) {
      view.clearAll();
      view.parse(value);
    }
  } else if (view.setValue) view.setValue(value);

  var subs = view.queryView(function(sub) {
    return sub.hasEvent && sub.hasEvent('onValue');
  }, 'all');

  if (subs.length) {
    for (var i = 0; i < subs.length; i++) {
      subs[i].callEvent('onValue', [value]);
    }
  }
}

Vue.component('webix-ui', {
  props: ['config', 'value', 'copyData'],
  watch: {
    value: {
      handler: data_handler,
    },
  },

  template: '<div></div>',

  mounted: function() {
    var config = webix.copy(this.config);
    config.$scope = this;

    this.webixId = webix.ui(config, this.$el);
    if (this.value) data_handler.call(this, this.value);
  },
  destroyed: function() {
    webix.$$(this.webixId).destructor();
  },
});

Vue.use(common);
/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core';

/* import font awesome icon component */
import { FontAwesomeIcon, FontAwesomeLayers } from '@fortawesome/vue-fontawesome';

/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons';
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons';
//import { far } from '@fortawesome/free-regular-svg-icons'
/* add icons to the library */
library.add(fas);
library.add(faSquare, faSquareCheck);

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.component('font-awesome-layers', FontAwesomeLayers)

import('./plugins/webix_10.1.2/gantt/gantt.min.js').then((module) => {
  window.gantt = module;
});
import('./plugins/webix_10.1.2/kanban/kanban.min.js');
import('./plugins/webix_10.1.2/gantt/gantt.min.css');
import('./plugins/webix_10.1.2/kanban/kanban.min.css');

registerLicense('Ngo9BigBOggjHTQxAR8/V1NBaF5cXmZCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXpfdnRWRWdeWURwXkY=');

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')