/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-12-14T11:43:17+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2021-12-16T12:16:37+02:00
 * @Copyright: Diversitude 2021
 */

import { uniqBy } from 'lodash'

export const state = {
    levelPeopleData: [],
    levelHiringData:[],
    levelEmployeesData:[],
    archivedLevelEmployeesData:[],
    levelJobsData:[],
    levelOnboardingData :[],
    levelLinkableAssets: [],
    levelOffboardingData :[],
    skillCategories: [],
    levelJob: null,

    companyJobs:[],

    //* get job details from selected job_id
    selectedJobDetails: null,

    activeJobId: -1,
    activeCandidateId: -1,
    activeSkillId: -1,
    activeHiringPositionId: -1,
    activeMemberId: null,

    //* team members performance
    teamMembersPerformance: null,
    memberObjectives: null,

    //* permissions tab
    system_roles: null,
    selected_user_permissions: null,
    last_used_level_id: -1,
    employeeDetails: null
}

export const actions = {
    clearAll({commit}){
        return new Promise((resolve) => {
            commit('resetStateData', null);
            resolve();
        })
    },
    // eslint-disable-next-line
    saveJobResponsibilities({}, payload){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/job/save_responsibilities', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error Saving Job Responsibilities', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveJobRoles({}, payload){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/job/save_roles', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error Saving Job Roles', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    deleteJobResponsibilities({}, payload){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/job/delete_responsibilities', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error Saving Job Responsibilities', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    deleteJobRoles({}, payload){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/job/delete_role', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Error Saving Job Roles', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveEmployeeDetails({}, payload){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/save_user_profile_text', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('People employee details error', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveEmployeeRating({}, payload){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/save_user_rating', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('People employee details error', error);
                reject();
            })
        })
    },
    loadPeopleData({commit}, level_id){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.get('api/people/employeesdata/' + level_id)
            .then((response) => {
                commit('setPeopleData', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },
    loadHiringData({commit}, level_id){
        //* clear old data before new data loads
        commit('setHiringData', []);
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.get('api/people/hiringdata/' + level_id)
            .then((response) => {
                commit('setHiringData', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    saveHiringPosition({}, payload){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/save-hiring-position', payload)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('People onbaording save error', error);
                reject();
            })
        })
    },
    loadEmployeesData({commit}, level_id){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.get('api/people/employeesdata/' + level_id)
            .then((response) => {
                commit('setEmployeesData', response.data);
                commit('setLastUsedLevelID', level_id)
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },
    loadEmployeeData({commit}, user_id){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.get('api/people/employeedata/' + user_id)
            .then((response) => {
                console.log(JSON.parse(JSON.stringify(response.data)))
                commit('setEmployeeData', response.data);
                //commit('setLastUsedLevelID', level_id)
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },
    loadCompanyJobs({commit}, company_id){
        //* reset jobs tables
        let data = { skills: [], onboarding_steps: [], offboarding_steps: []}
        commit('setJobDetails', data);
        commit('setCompanyJobsData', []);
        //* load new data
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.get(`api/people/job/level_jobs/`, { params: {level_id: company_id} })
            .then((response) => {
                commit('setCompanyJobsData', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },
    loadLevelJobsData({commit}, company_id){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.get(`api/people/leveljobs/${company_id}`)
            .then((response) => {
                commit('setLevelJobsData', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error);
                reject();
            })
        })
    },
    // eslint-disable-next-line
    loadJobDetails({dispatch,commit}, job_id){
        //TODO - add api endpoint again
        // eslint-disable-next-line
        return new Promise( (resolve, reject) => {
            //* reset while loading
            let data = {
                //skills: [],
                onboarding_steps: [],
                offboarding_steps: [],
            }
            commit('setJobDetails', data);
            
            //let skills_promise = dispatch('loadSkillsForJob',job_id);
            // let onboarding_promise = dispatch('loadOnboardingChecksForJob', job_id);
            // let offboarding_promise = dispatch('loadOffboardingChecksForJob', job_id);

            // Promise.all([onboarding_promise, offboarding_promise])
            // .then( () => {
            //     resolve();
            //     commit('setJobDetails', data);
            // })
            // .catch((e)=>{
            //     console.log(e, 'error loading data');
            //     reject();
            // })
        })
    },
    // //eslint-disable-next-line
    // loadSkillsForJob({commit}, job_id){
    //     return new Promise((resolve, reject) => {
    //         window.axios.get(`api/people/job/${job_id}/skills`)
    //         .then((response) => {
    //             commit('setSelectedJobSkills',response.data.data )
    //             resolve();
    //         })
    //         .catch((error) => {
    //             console.log('Error:', error.message);
    //             reject();
    //         })
    //     })
    // },
    //eslint-disable-next-line
    getLevelJob({commit}, job_id){
        if(job_id == null) {
            commit('setSelectedLevelJob', null)
        } else {
            return new Promise((resolve, reject) => {
                window.axios.get('api/people/job/job', { params: {job_id: job_id} })
                .then((response) => {
                    commit('setSelectedLevelJob',response.data )
                    resolve();
                })
                .catch((error) => {
                    console.log('Error:', error.message);
                    reject();
                })
            })
        }
    },
    //eslint-disable-next-line
    loadSkillCategories({commit}){
        return new Promise((resolve, reject) => {
            window.axios.get(`api/people/job/skill_categories`)
            .then((response) => {
                commit('setSkillCategories',response.data )
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error.message);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadSkills({commit}, job_id){
        return new Promise((resolve, reject) => {
            window.axios.get(`api/people/job/skill_categories`)
            .then((response) => {
                console.log(response)
                commit('setSelectedJobSkills',response.data )
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error.message);
                reject();
            })
        })
    },
    //eslint-disable-next-line
    loadOnboardingChecksForJob({commit}, job_id){
        return new Promise((resolve, reject) => {
            window.axios.get(`api/people/job/${job_id}/on-boarding`)
            .then((response) => {
                commit('setSelectedJobOnboardingSteps',response.data.data )
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error.message);
                reject();
            })
        })
    },
    loadOffboardingChecksForJob({commit}, job_id){
        return new Promise((resolve, reject) => {
            window.axios.get(`api/people/job/${job_id}/off-boarding`)
            .then((response) => {
                commit('setSelectedJobOffboardingSteps',response.data.data )
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error.message);
                reject();
            })
        })
    },
    loadOnboardingData({commit}, level_id){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.get('api/people/onboardingdata/' + level_id)
            .then((response) => {
                commit('setOnboardingData', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error.message);
                reject();
            })
        })
    },
    loadLinkableAssets({commit}, level_id){
        console.log("getting assets");
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.get('api/people/linkableassets', { params: {level_id: level_id} })
            .then((response) => {
                commit('setLinkableAssets', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error.message);
                reject();
            })
        })
    },
    resetJobTab({commit}){
        let data = {
            skills: [],
            onboarding_steps: [],
            offboarding_steps: [],
        }
        commit('setJobDetails', data);
    },
     //eslint-disable-next-line no-empty-pattern
    saveOnboardingChecks({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/saveonboardingchecks', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('People onbaording save error', error);
                reject();
            })
        })
    },
         //eslint-disable-next-line no-empty-pattern
         saveOnboardingTracking({}, params) {
            return new Promise((resolve, reject) => {
                //eslint-disable-next-line no-undef
                window.axios.post('api/people/saveonboardingtracking', params)
                .then(()=>{
                    resolve();
                })
                .catch((error) => {
                    console.log('People onbaording track save error', error);
                    reject();
                })
            })
        },
        //eslint-disable-next-line no-empty-pattern
        deleteOnboardingCheck({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/deleteonboardingcheck', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Delete onboarding check error', error);
                reject();
            })
        })
    },
    //* --------- offboarding start **/
    loadOffboardingData({commit}, level_id){
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.get('api/people/offboardingdata/' + level_id)
            .then((response) => {
                commit('setOffboardingData', response.data);
                resolve();
            })
            .catch((error) => {
                console.log('Error:', error.message);
                reject();
            })
        })
    },
     //eslint-disable-next-line no-empty-pattern
        saveOffboardingChecks({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/saveoffboardingchecks', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('People offbaording save error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveOffboardingTracking({}, params) {
            return new Promise((resolve, reject) => {
                //eslint-disable-next-line no-undef
                window.axios.post('api/people/saveoffboardingtracking', params)
                .then(()=>{
                    resolve();
                })
                .catch((error) => {
                    console.log('People offbaording track save error', error);
                    reject();
                })
            })
        },
    //eslint-disable-next-line no-empty-pattern
    deleteOffboardingCheck({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/deleteoffboardingcheck', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Delete offboarding check error', error);
                reject();
            })
        })
    },
    //* offboarding end */
    //eslint-disable-next-line no-empty-pattern
    updateEmployeeJob({}, params){
        return new Promise((resolve, reject) => {
            window.axios.post('api/people/job/update_employee_job', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('People job error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveHiringJob({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/job/save_jobs', {jobs: params})
            .then((response)=>{
                resolve(response);
            })
            .catch((error) => {
                console.log('People job error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveJobDetails({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/savejob', {jobs: params})
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('People job error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveEmployee({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/newemployee', params)
            .then((response)=>{
                resolve(response.data.user_id);
            })
            .catch((error) => {
                console.log('Employee create error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    uploadAvatar({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/uploadavatar', params)
            .then((response)=>{
                resolve(response);
            })
            .catch((error) => {
                console.log('Avatar upload error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveEmployeeLeave({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/saveleave', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Employee leave update error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveEmployeeRemuneration({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/saveremuneration', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Employee remuneration update error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveEmployeeAssets({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/saveassets', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Employee asset update error', error);
                reject();
            })
        })
    },
     //eslint-disable-next-line no-empty-pattern
     updateEmployee({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/updateemployee', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Employee update error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    updateEmployeeLevel({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post(`api/people/user/${params.id}/level/${params.level_id}`)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('Employee update error', error);
                reject();
            })
        })
    },
     //eslint-disable-next-line no-empty-pattern
     saveJobDescription({}, params) {
         return new Promise((resolve, reject) => {
             //eslint-disable-next-line no-undef
             window.axios.post('api/people/savejobdescription', params)
             .then(()=>{
                 resolve();
             })
             .catch((error) => {
                 console.log('People job error', error);
                 reject();
             })
         })
     },
     //eslint-disable-next-line no-empty-pattern
     saveJobCandidate({}, params) {
         return new Promise((resolve, reject) => {
             //eslint-disable-next-line no-undef
             window.axios.post('api/people/savecandidate', params)
             .then(()=>{
                 resolve();
             })
             .catch((error) => {
                 console.log('People job error', error);
                 reject();
             })
         })
     },
     //eslint-disable-next-line no-empty-pattern
    saveJobSkill({}, params) {
        //* save skill name/description for a job
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/job/save_job_skills', params)
            .then((response)=>{
                resolve(response.data.id);
            })
            .catch((error) => {
                console.log('People job error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveUserSkill({}, params) {
        //* save skill name/description for a job
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/job/save_user_job_skills', params)
            .then((response)=>{
                resolve(response.data.id);
            })
            .catch((error) => {
                console.log('User job error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveEmployeeSkill({}, params) {
        //* save skill name/description for a job
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/job/save_employee_skills', params)
            .then((response)=>{
                resolve(response.data.id);
            })
            .catch((error) => {
                console.log('People job error', error);
                reject();
            })
        })
    },
     //eslint-disable-next-line no-empty-pattern
     saveCandidateSkill({}, params) {
        //* save the user rating with for the selected skill/candidate
         return new Promise((resolve, reject) => {
             //eslint-disable-next-line no-undef
             window.axios.post('api/people/savecandidateskill', params)
             .then((response)=>{
                 resolve(response.data.id);
             })
             .catch((error) => {
                 console.log('People job error', error);
                 reject();
             })
         })
     },
     //eslint-disable-next-line no-empty-pattern
     saveCandidateQualification({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/savequalifications', params)
            .then((response)=>{
                resolve(response.data.id);
            })
            .catch((error) => {
                console.log('People job error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    saveInterviewComments({}, params) {
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/saveinterview', params)
            .then((response)=>{
                resolve(response.data.id);
            })
            .catch((error) => {
                console.log('People job error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    linkEmployeesAssets({}, params) {
        console.log(params)
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/linkasset', params)
            .then((response)=>{
                resolve(response);
            })
            .catch((error) => {
                console.log('link assets error', error);
                reject();
            })
        })
    },
     //eslint-disable-next-line no-empty-pattern
     updateAssetsLink({}, params) {
        console.log(params)
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/updateassetlink', params)
            .then((response)=>{
                resolve(response);
            })
            .catch((error) => {
                console.log('link assets error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    unlinkEmployeesAssets({}, params) {
        console.log(params);
        return new Promise((resolve, reject) => {
            //eslint-disable-next-line no-undef
            window.axios.post('api/people/unlinkasset', params)
            .then((response)=>{
                resolve(response);
            })
            .catch((error) => {
                console.log('link assets error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    deleteHiringPosition({}, params) {
        return new Promise((resolve, reject) => {
             //eslint-disable-next-line no-undef
            window.axios.post('api/people/delete_hiring_position', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('People job error', error);
                reject();
            })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    deleteEmployeeRemuneration({}, params) {
        return new Promise((resolve, reject) => {
             //eslint-disable-next-line no-undef
             window.axios.post('api/people/deleteremuneration', params)
             .then(()=>{
                 resolve();
             })
             .catch((error) => {
                 console.log('People Remuneration', error);
                 reject();
             })
        })
    },
    //eslint-disable-next-line no-empty-pattern
    deleteJob({}, params) {
        return new Promise((resolve, reject) => {
             //eslint-disable-next-line no-undef
            window.axios.post('api/people/job/delete_job', params)
            .then(()=>{
                resolve();
            })
            .catch((error) => {
                console.log('People job error', error);
                reject();
            })
        })
    },
     //eslint-disable-next-line no-empty-pattern
     deleteJobCandidate({}, params) {
         return new Promise((resolve, reject) => {
             //eslint-disable-next-line no-undef
             window.axios.post('api/people/deletecandidate', params)
             .then(()=>{
                 resolve();
             })
             .catch((error) => {
                 console.log('People job error', error);
                 reject();
             })
         })
     },
     //eslint-disable-next-line no-empty-pattern
     deleteJobSkill({}, params) {
         return new Promise((resolve, reject) => {
             //eslint-disable-next-line no-undef
             window.axios.post('api/people/job/delete_job_skill', params)
             .then(()=>{
                 resolve();
             })
             .catch((error) => {
                 console.log('People job error', error);
                 reject();
             })
         })
     },
     //eslint-disable-next-line no-empty-pattern
     deleteCandidateQualification({}, params) {
         return new Promise((resolve, reject) => {
             //eslint-disable-next-line no-undef
             window.axios.post('api/people/deletequalification', params)
             .then(()=>{
                 resolve();
             })
             .catch(() => {
                 reject();
             })
         })
     },
    setActiveSkillId({commit}, id){
        commit('setActiveSkill', id)
    },
    setActiveCandidateId({commit}, id){
        commit('setActiveCandidate', id)
    },
    setActiveJobId({commit}, id){
        commit('setActiveJob', id)
    },
    setActiveMemberId({commit}, id){
        commit('setActiveMember', id)
    },
    setActiveHiringId({commit}, id){
        commit('setActiveHiringPosition', id)
    },

    //* team members performance
    //*  {host}/api/levels/{levelId}/team-members
    getTeamMembersPerformance({commit}, level_id){
        return new Promise( (resolve, reject) => {
            window.axios.get(`api/levels/${level_id}/team-members`) // 60 for testing ${level_id}
            .then((response)=>{
                commit('setTeamMembersPerformance', response.data.data);
                resolve();
            })
            .catch((e)=>{
                reject(e);
            })
        })
    },

    //* {host}api/levels/team-member/${member_id}
    getTeamMemberObjectives({commit}, member_id){
        return new Promise( (resolve, reject) => {
            window.axios.get(`api/levels/team-member/${member_id}`) // 35 for testing ${level_id}
            .then((response)=>{
                commit('setTeamMemberObjectives', response.data.data);
                resolve();
            })
            .catch((e)=>{
                reject(e);
            })
        })
    },
    // eslint-disable-next-line
    getObjectiveRating({commit}, payload){
        // eslint-disable-next-line
        return new Promise( (resolve, reject) => {
            window.axios.get(`api/levels/member/${payload.member_id}/objective_ratings/${payload.objective_id}`) // 35 for testing ${level_id}
            /*.then((response)=>{
                commit('setTeamMemberObjectives', response.data.data);
                resolve();
            })
            .catch((e)=>{
                reject(e);
            })*/
        })
    },
    loadSystemPermissions({commit}){
        return new Promise( (resolve, reject) => {
            window.axios.get(`api/people/roles-permission/`)
            .then((response)=>{
                commit('setSystemPermissions', response.data);
                resolve();
            })
            .catch((e)=>{
                reject(e);
            })
        })
    },
    loadUserPermissions({commit}, user_id){
        return new Promise( (resolve, reject) => {
            window.axios.get(`api/people/roles-permission/${user_id}`)
            .then((response)=>{
                commit('setUserPermissions', response.data);
                resolve();
            })
            .catch((e)=>{
                reject(e);
            })
        })
    },
    //eslint-disable-next-line
    saveRolesAndPermissions({}, payload){
        return new Promise( (resolve, reject) => {
            window.axios.post(`api/people/save-roles-permission/${payload.user_id}`, payload.save_data)
            .then(()=>{
                resolve();
            })
            .catch((e)=>{
                reject(e);
            })
        })
    }
 }

 export const mutations = {
    setLastUsedLevelID(state, payload){
        state.last_used_level_id = payload;
    },

    setActiveSkill(state, payload){
        state.activeSkillId = payload;
    },
    setPeopleData(state, payload) {
        state.levelPeopleData = payload;
    },
    setHiringData(state, payload) {
        state.levelHiringData = payload;
    },
    setEmployeesData(state, payload) {
        let active_users = uniqBy(payload.active, 'id');
        let archived_users = uniqBy(payload.archived).map( user => {
            return { ...user, archived: true}
        });
        state.levelEmployeesData = [...active_users, ...archived_users];
        //state.levelEmployeesData = uniqBy(payload.active, 'id');
        //state.archivedLevelEmployeesData = uniqBy(payload.archived).map( user => {
        //    return { ...user, archived: true}
        //});
    },
    setEmployeeData(state, payload) {
        state.employeeDetails = payload;
    },
    setLevelJobsData(state, payload) {
        state.levelJobsData = payload;
    },
    setJobDetails(state, payload){
        state.selectedJobDetails = payload;
    },
    setOnboardingData(state, payload) {
        state.levelOnboardingData = payload;
    },
    setLinkableAssets(state, payload) {
        state.levelLinkableAssets = payload;
    },
    setOffboardingData(state, payload) {
        state.levelOffboardingData = payload;
    },
    resetStateData(state){
        state.levelPeopleData = [];
        state.levelHiringData = [];
        state.levelEmployeesData = [];
        state.levelJobsData = [];
        state.levelOnboardingData = [];
        state.levelLinkableAssets = [];
        state.levelOffboardingData = [];
        state.activeJobId = -1;
        state.activeCandidateId = -1;
        state.activeSkillId = -1;
        state.activeMemberId = null;
        state.system_permissions = null;
        state.selected_user_permissions = null;
        state.employeeDetails = null
    },

    setActiveJob(state, id) {
        state.activeJobId = id;
    },

    setActiveMember(state, id) {
        state.activeMemberId = id;
    },
    setActiveHiringPosition(state, id) {
        state.activeHiringPositionId = id;
    },
    setActiveCandidate(state, id){
        state.activeCandidateId = id;
    },

    //* jobs tab
    setCompanyJobsData(state, payload) {
        state.companyJobs = payload;
    },
    setSelectedJobSkills(state, payload) {
        state.selectedJobDetails.skills = payload;
    },
    setSelectedJobOnboardingSteps(state, payload) {
        state.selectedJobDetails.onboarding_steps = payload;
    },
    setSelectedJobOffboardingSteps(state, payload) {
        state.selectedJobDetails.offboarding_steps = payload;
    },
    setSkillCategories(state, payload) {
        state.skillCategories = payload
    },
    setSelectedLevelJob(state, payload) {
        state.levelJob = payload
    },


    //* team members perf mutations
    setTeamMembersPerformance(state, payload) {
        state.teamMembersPerformance = payload;
    },
    setTeamMemberObjectives(state, payload) {
        state.memberObjectives = payload;
    },
    setSystemPermissions(state, payload){
        state.system_roles = payload;
    },
    setUserPermissions(state, payload){
        state.selected_user_permissions = payload;
    }
 }
