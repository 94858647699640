/**
 * @Author: Calvin Green <calvin>
 * @Date:   2022-02-10T09:50:20+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2022-03-09T14:21:18+02:00
 * @Copyright: Diversitude 2022
 */



import Vue from 'vue'
import Vuex from 'vuex'

import modules from './modules'

Vue.use(Vuex)

//* sync states to local storage
const localStoragePlugin = store => {
  //eslint-disable-next-line
  store.subscribe((mutation, state) => {
    console.log(mutation.type)
    // if (mutation.type.startsWith('components/setComponentState')) {
    //   localStorage.setItem('component_states', JSON.stringify(state.yourModule.component_states));
    // }
  });
};

const store = new Vuex.Store({
  modules,
  localStoragePlugin,
  // Enable strict mode in development to get a warning
  // when mutating state outside of a mutation.
  // https://vuex.vuejs.org/guide/strict.html
  strict: process.env.NODE_ENV !== 'production',
})

export default store
