import {cloneDeep} from 'lodash';
import Vue from 'vue';
export const state = {
    last_id: null,
    has_level_tree_loaded: false,

    //* each table uid will have its own clipboard object (uses uid as key)
    clipboard:[],
    batch_clipboard: [],
    duplicate_clipboard: [],

    is_editing_table: false,
    table_states: {},
    table_states_array:[],
    isCtrlPressed: false,

    //* track expanded state of treetables
    //* key of the state object will be named after the tables uid
    treetable_states: {},

    dataset_table_states:{},

    active_card_ids:{},

    expanded_card_ids:[],

    auto_save: false
};

export const getters = {
    last_id: state => state.last_id,
    hasUnsavedData: state => state.is_editing_table,
    isEditingAnotherTable: () => {
        return false;
    },
};

export const mutations = {
    pushExpandedID(state, id) {
        // Ensure the id is not already in the array to avoid duplicates
        if (!state.expanded_card_ids.includes(id)) {
        state.expanded_card_ids.push(id);
        }
    },
    
    // Mutation to remove an ID from the array
    spliceExpandedID(state, id) {
        const index = state.expanded_card_ids.indexOf(id);
        if (index > -1) {
        state.expanded_card_ids.splice(index, 1);
        }
    },
    updateActiveCardID(state, { uid, item_id}) {
        Vue.set(state.active_card_ids, uid, item_id)
    },
    setID(state, newValue) {
        state.last_id = newValue
    },
    updateLevelTreeLoadingState(state, newValue) {
        state.has_level_tree_loaded = newValue;
    },
    insertIntoClipboard(state, payload){
        //* payload contains 'uid' and a 'data' array of row objects
        if(payload.data != null){
            let clipboard_row = cloneDeep(payload.data)
            clipboard_row['uid'] = payload.uid
            let index = state.clipboard.findIndex( item => {
                return item.uid == payload.uid
            })
            if(index != -1){
                state.clipboard[index] = clipboard_row
            }
            else{
                state.clipboard.push(clipboard_row)
            }
        }
        else{
            let index = state.clipboard.findIndex( item => {
                return item.uid == payload.uid
            })
            if(index != -1){
                Vue.delete(state.clipboard, index);
            }
        }
        
        //* parmas -> uid = wbix tables uid -- data = entire row 
        //state.clipboard[payload.uid] = payload.data;
    },
    insertIntoBatchClipboard(state, payload){
        if(payload.data != null){
            let index = state.batch_clipboard.findIndex( item => {
                return item.uid === payload.uid;
            })
            let clipboard_rows = cloneDeep(payload.data)
            let clipboard_payload = {
                uid: payload.uid,
                rows: clipboard_rows,
            }
            if(index != -1){
                state.batch_clipboard[index] = clipboard_payload
            }
            else{
                state.batch_clipboard.push(clipboard_payload)
            }
            //* create new clipboard named after the table uid
            //let clipboard_rows = cloneDeep(payload.data)
            //state.batch_clipboard[payload.uid] = clipboard_rows
        }
        else{
            let index = state.batch_clipboard.findIndex( item => {
                return item.uid == payload.uid;
            })
            if(index != -1){
                Vue.delete(state.batch_clipboard, index);
            }
        }
    },
    insertIntoDuplicationClipboard(state, payload){
        if(payload.data != null){
            let index = state.duplicate_clipboard.findIndex( item => {
                return item.uid === payload.uid;
            })
            let clipboard_rows = cloneDeep(payload.data)
            let clipboard_payload = {
                uid: payload.uid,
                rows: clipboard_rows,
            }
            if(index != -1){
                state.duplicate_clipboard[index] = clipboard_payload
            }
            else{
                state.duplicate_clipboard.push(clipboard_payload)
            }
        }
        else{
            let index = state.duplicate_clipboard.findIndex( item => {
                return item.uid === payload.uid;
            })
            state.duplicate_clipboard.splice(index, 1);
        }
    },


    setEditingState(state, payload){
        //* table_uid -> payload.uid
        //* payload.state values could be -> changes_made, is_editing_table, show_datatable_filters
        if(payload.state == null){
            if(state.table_states[payload.uid] != undefined){
                Vue.delete(state.table_states, payload.uid);
            }
            return;
        }

        if(state.table_states[payload.uid] != undefined){
            state.table_states = {
                ...state.table_states, 
                [payload.uid]: { ...state.table_states[payload.uid], ...payload.state,}
            }
        }
        else{
            state.table_states[payload.uid] = payload.state;
        }

        state.table_states_array = [];
        //eslint-disable-next-line
        for (const [key, value] of Object.entries(state.table_states)) {
            state.table_states_array.push(value)
        }
    },

    setCtrlPressedState(state, payload){
        state.isCtrlPressed = payload
    },

    setAutoSaveState(state, payload) {
        state.auto_save = payload
    },

    saveTreetableState(state, payload){ //{uid, row_state}
        let table_uid = payload.uid;
        if(payload.row_state == null){
            if(state.treetable_states[table_uid] != undefined){
                delete state.treetable_states[table_uid];
            }
            return;
        }
        else{
            state.treetable_states[table_uid] = payload.row_state;
        }
        window.localStorage.setItem('treetable_states', JSON.stringify(state.treetable_states))
    },
    restoreTreeTableState(state, payload){
        state.treetable_states = payload;
    },
    saveDatasetTableState(state, payload){
        let table_uid = payload.uid;
        if(payload.col_state == null){
            if(state.dataset_table_states[table_uid] != undefined){
                delete state.dataset_table_states[table_uid];
            }
            return;
        }
        else{
            state.dataset_table_states[table_uid] = payload.col_state;
        }


    },
};

export const actions = {
    updateActiveCardID({commit}, payload){
        commit('updateActiveCardID', payload);
    },
    setLastEditorID({ commit }, id) {
        commit('setID', id);
    },
    setEditingState({commit}, state){
        commit('setEditingState', state)
    },
    setLevelTreeLoadedState({ commit }, state) {
        commit('updateLevelTreeLoadingState', state);
    },
    insertIntoClipboard({commit}, payload){
        commit('insertIntoClipboard', payload);
    },
    insertIntoBatchClipboard({commit}, payload){
        commit('insertIntoBatchClipboard', payload);
    },

    insertIntoDuplicationClipboard({commit}, payload){
        commit('insertIntoDuplicationClipboard', payload);
    },

    setCtrlPressedState({commit}, payload){
        commit('setCtrlPressedState', payload);
    },

    //* save treetable state for uid
    saveTreetableState({commit}, payload){
        commit('saveTreetableState', payload);
    },

    toggleAutoSave({commit}, payload) {
        commit('setAutoSaveState', payload)
    },

    saveDatasetTableState({commit}, payload){
        commit('saveDatasetTableState', payload);
    },
    updateCardExpandedState({commit, state}, id){
        let found = state.expanded_card_ids.includes(id);
        if(found){
            commit('spliceExpandedID', id);
        }
        else{
            commit('pushExpandedID', id);
        }
    }
};
