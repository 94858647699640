//eslint-disable-next-line
import axios from 'axios'
//eslint-disable-next-line
import store from '@/state/store'

export const state = {
    scorecard: {

    },
    scorecard_values:[],
    scorecard_objectives:[],
    scorecard_skills:[],
    scorecard_productivity:[],

    scorecard_modifiers:[],
    scorecard_qualifiers:[],

};

export const mutations = {};

export const actions = {};