
//eslint-disable-next-line
import store from '@/state/store'

export const state = {
    scorecard_core: {
        //* card core data
        roles:[],
        templates:[],
    },
    
    selected_scorecard:{
        id: -1,
        reviewed: false,
        in_progress: false,
        final_score: 0,
    },

    scorecard_values:[],
    scorecard_objectives:[],
    scorecard_skills:[],
    scorecard_productivity:[],

    scorecard_modifiers:[],
    scorecard_qualifiers:[],


};

export const mutations = {};

export const actions = {
    //eslint-disable-next-line
    loadScorecardCore({commit}, params){
        //eslint-disable-next-line
        return new Promise( (resolve, reject) => {
            window.axios.get('api/scorecard_core', { params: params})
            .then((response) => {
                console.log(response);
            })
            .catch((error)=>{
                console.error(error);
            })
        })
    },

    //eslint-disable-next-line
    loadSelectedScorecard({commit}, params){
        //eslint-disable-next-line
        return new Promise( (resolve, reject) => {
            window.axios.get('api/scorecard_core', { params: params})
            .then((response) => {
                console.log(response);
            })
            .catch((error)=>{
                console.error(error);
            })
        })
    },
};