/**
 * @Author: Calvin Green <calvin>
 * @Date:   2021-05-08T11:48:33+02:00
 * @Email:  calvin@diversitude.com
 * @Last modified by:   calvin
 * @Last modified time: 2021-05-08T13:46:01+02:00
 * @Copyright: Diversitude 2021
 */



import Vue from 'vue'
import VueRouter from 'vue-router'
import VueMeta from 'vue-meta'

import store from '@/state/store'
import routes from './routes'
import Swal from 'sweetalert2'
import tableHelper from '@/mixins/tableHelpers.js';

Vue.use(VueRouter)
Vue.use(VueMeta, {
    // The component option name that vue-meta looks for meta info on.
    keyName: 'page',
})

const router = new VueRouter({
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    mode: 'history',
    // Simulate native-like scroll behavior when navigating to a new
    // route and using back/forward buttons.
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
})

// Before each route evaluates...
router.beforeEach(async (routeTo, routeFrom, next) => {
    // Check if auth is required on this route
    // (including nested routes).
    const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
    const unsavedChangesCheck = routeTo.matched.some((route) => route.meta.unsavedChangesCheck)


    //logic to rather close the details overlay when pressing back on the browser
    // if (routeFrom.name === "scorecard" && routeTo.name !== "scorecard") {
    //     window.history.replaceState(null, null, routeFrom.fullPath);
    //     return false;
    // }

    // If auth isn't required for the route, just continue.
    if (!authRequired && !unsavedChangesCheck) return next()

    //* first check if the route has unsaved data in any of the comonents
    if (unsavedChangesCheck) {
        const unsaved_changes = await tableHelper.methods.$allTablesUnsavedChangesCheck();
        if (unsaved_changes) {
            store.dispatch('menu/setLoading', false);
            return false;
        }
        if (store.getters['process/hasUnsavedData']) {
            store.dispatch('menu/setLoading', false);
            Swal.fire({
                title: "Unsaved Changes Found",
                text: "Continue without saving?",
                icon: "warning",
                showCancelButton: true,
                confirmButtonColor: "#34c38f",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
            }).then((result) => {
                if (result.value) {
                    Vue.prototype.$eventHub.$emit('clearDocumentChanges');
                    setTimeout(() => {
                        next({ name: routeTo.name });
                    }, 50)

                }
            });
            return false
        }
    }


    // If auth is required and the user is logged in...
    if (store.getters['auth/loggedIn']) {
        // Validate the local user token...
        return store.dispatch('auth/validate').then((validUser) => {
            // Then continue if the token still represents a valid user,
            // otherwise redirect to login.
            validUser ? next() : redirectToLogin()
        })
            .catch(() => {
                redirectToLogin();
            })
    }
    // If auth is required and the user is NOT currently logged in,
    // redirect to login.
    redirectToLogin();
    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line no-inner-declarations
    function redirectToLogin() {
        // Pass the original route to the login component
        next({
            name: 'login',
            query: {
                redirectFrom: routeTo.fullPath
            }
        })
    }



})

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            // Complete the redirect.
                            next(...args)
                            reject(new Error('Redirected'))
                        } else {
                            resolve()
                        }
                    })
                } else {
                    // Otherwise, continue resolving the route.
                    resolve()
                }
            })
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return
    }

    // If we reach this point, continue resolving the route.
    next()
})

export default router