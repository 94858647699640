
// import axios from 'axios'
// import store from '@/state/store'
// import { format } from 'date-fns';

export const state = {
    selected_objectives: [],
};

export const mutations = {
    setSelectedObjectivesArray(state, array) {
        state.selected_objectives = array;
    },
};

export const actions = {
    setSelectedPlanningObjectives({commit}, array){
        commit('setSelectedObjectivesArray', array)
    },
};